import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { device } from '../../../styles/mediaQueries'

gsap.registerPlugin(ScrollTrigger);

const ImageScrollAnim = (tl, imageItems, imageSection) => {

    // make the right edge "stick" to the scroll bar. force3D: true improves performance
    gsap.set(imageItems, { transformOrigin: 'center center', force3D: true });

            const tl1 = gsap.timeline({
                scrollTrigger: {
                    trigger: imageSection,
                    start: 'top bottom',
                    end: 'top 35%',
                    scrub: 1,
                }
            })
            .from(imageSection, {
            //     autoAlpha: 0,
                scale: 0.85,
            })
        
            imageItems.forEach((img, idx) => {
                const q = gsap.utils.selector(img);
                const proxy = { skew: 0 };
                const skewSetter = gsap.quickSetter(imageItems, 'skewY', 'deg'); // fast
                const clamp = gsap.utils.clamp(-20, 20); // don't let the skew go beyond 20 degrees.
                const imageWrapperHeight = img.offsetHeight
        
                tl.current = gsap
                    .timeline({
                    scrollTrigger: {
                        onUpdate: (self) => {
                            const skew = clamp(self.getVelocity() / 800);
                            // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
                            if (Math.abs(skew) > Math.abs(proxy.skew)) {
                                proxy.skew = skew;
                                gsap.to(proxy, {
                                skew: 0,
                                duration: 0.8,
                                ease: 'power3',
                                overwrite: true,
                                onUpdate: () => skewSetter(proxy.skew),
                                });
                            }
                            },
                        trigger: img,
                        start: 'top top',
                        scrub: 1,
                    },
                    })
                    .to(q('.imgWrapper'),{
                        yPercent: 15,
                        autoAlpha: 0.25,
                        scale: .75,
                    }, '>')
            })

}

export default ImageScrollAnim;