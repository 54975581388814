import React, { useRef, useEffect, useState } from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Container from "../components/container";
import Header from "../components/header/header";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import HomePageSection from "../components/home-page-section";
import { useMediaQuery } from "react-responsive";

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    homePageSections: allSanityHomeImageSections(sort: { fields: [orderRank], order: ASC }) {
      edges {
        node {
          id
          imageLayout
          gridLayout
          image1 {
            alt
            asset {
              url
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: AUTO)
            }
          }
          image2 {
            alt
            asset {
              url
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: AUTO)
            }
          }
          image3 {
            alt
            asset {
              url
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: AUTO)
            }
          }
        }
      }
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  const [imageToShow, setimageToShow] = useState({ isOpen: false, img: null });

  const [open, setOpen] = React.useState({
    isOpen: false,
    img: null
  });

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  const homeSectionNodes = (data || {}).homePageSections
    ? mapEdgesToNodes(data.homePageSections)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      {/* <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw'}}>
        <h1 style={{ textAlign: "center", fontSize: '8rem' }}>🇵🇸</h1>
      </div> */}
      <Container>
        
         {homeSectionNodes && (
          homeSectionNodes.map((node, idx) => {
            return (
              <HomePageSection
                key={idx}
                node={node}
                setimageToShow={setimageToShow}
                setOpen={setOpen}
              />
            )
          })
        )}
        <Lightbox
          open={open.isOpen}
          close={() => setOpen({isOpen: false, img: null})}
          slides={[
            {
              src: open.img,
              alt: "image 1",
              width: 3840,
              height: 2560,
            }
          ]}
        /> 
      </Container>
    </Layout>
  );
};

export default IndexPage;
