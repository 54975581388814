import React, { useRef, useEffect, useState, useLayoutEffect } from 'react'
import classNames from 'classnames';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import {GatsbyImage} from 'gatsby-plugin-image'
import ImageScrollAnim from './animation'
import { ImageSection, ImageWrapper, ImageButton } from './home-page-section.styled'

const HomePageSection = ({node, setimageToShow, setOpen}) => {

    gsap.registerPlugin(ScrollTrigger);
    
    // const [indexImageToShow, setIndexImageToShow] = useState()
    // const [imageToShow, setimageToShow] = useState({isOpen: false, img: null})
    // const [isOpen, setIsOpen] = useState(false)

    const {image1, image2, image3, imageLayout, gridLayout} = node;

    const ref = useRef(null);

    const tl = useRef()
    const revealRefs = useRef([]);

    revealRefs.current = [];

    const addToRefs = (el) => {
        if(el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el)
        }
    };

    const imageItems = revealRefs.current;
    const imageSectionRef = useRef(null);

    // useEffect(() => {
    //     ImageScrollAnim(tl, imageItems, imageSectionRef.current)
	// }, [imageItems])

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {

        // make the right edge "stick" to the scroll bar. force3D: true improves performance
        gsap.set(imageItems, { transformOrigin: 'center center', force3D: true });

        const tl1 = gsap.timeline({
            scrollTrigger: {
                trigger: imageSectionRef.current,
                start: 'top bottom',
                end: 'top 35%',
                scrub: 1,
            }
        })
        .from(imageSectionRef.current, {
        //     autoAlpha: 0,
            scale: 0.85,
        })

        imageItems.forEach((img, idx) => {
            const q = gsap.utils.selector(img);
            const proxy = { skew: 0 };
            const skewSetter = gsap.quickSetter(imageItems, 'skewY', 'deg'); // fast
            const clamp = gsap.utils.clamp(-20, 20); // don't let the skew go beyond 20 degrees.
            const imageWrapperHeight = img.offsetHeight

            tl.current = gsap
                .timeline({
                scrollTrigger: {
                    onUpdate: (self) => {
                        const skew = clamp(self.getVelocity() / 800);
                        // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
                        if (Math.abs(skew) > Math.abs(proxy.skew)) {
                            proxy.skew = skew;
                            gsap.to(proxy, {
                            skew: 0,
                            duration: 0.8,
                            ease: 'power3',
                            overwrite: true,
                            onUpdate: () => skewSetter(proxy.skew),
                            });
                        }
                        },
                    trigger: img,
                    start: 'top top',
                    scrub: 1,
                },
                })
                .to(q('.imgWrapper'),{
                    yPercent: 15,
                    autoAlpha: 0.25,
                    scale: .75,
                }, '>')
            })
            
        }, ref);
        return () => ctx.revert()
      }, [])

    return (
        <div style={{position: 'relative'}} ref={ref}>
            <ImageSection 
                className={classNames({
                    'screenHeight': imageLayout === 'largeTop' || imageLayout === 'twoStacked',
                    'twoColGrid': gridLayout === 'twoCol',
                    'threeColGrid': gridLayout === 'threeCol',
                    'sixColGrid': gridLayout === 'sixCol',
                    'twelveColGrid': gridLayout === 'twelveCol',
                })}
                ref={imageSectionRef}
                tabIndex="1"
            > 
            {image1 && image1.asset && (
                <ImageWrapper
                    className= {classNames({
                        'lg-l': imageLayout === 'largeLeft' || imageLayout === 'twoEqualCols',
                        'sm-l-t': imageLayout === 'largeRight',
                        'lg-t': imageLayout === 'largeTop',
                        'center-top': imageLayout === 'twoStacked' && gridLayout === 'twelveCol',
                        'uneven-col-left': imageLayout === 'twoColsLgLeft' && gridLayout === 'sixCol',
                        'homeImage': true
                    })}
                    ref={addToRefs}
                    // onClick={() => setimageToShow({isOpen: true, img: image1.asset.gatsbyImageData.images.fallback.src})}
                    onClick={() => setOpen({isOpen: true, img: image1.asset.url})}
                >
                    <GatsbyImage 
                        image={image1.asset.gatsbyImageData}
                        alt={image1.alt}
                        quality="50"
                        style={{height: '100%'}}
                        className='imgWrapper'
                        imgClassName="imgFile"
                        layout="fixed"
                    />
                </ImageWrapper>
                )}
                {image2 && image2.asset && (
                <ImageWrapper 
                    className= {classNames({
                        'sm-r-t': imageLayout === 'largeLeft',
                        'sm-l-b': imageLayout === 'largeRight' || imageLayout === 'largeTop',
                        'lg-r': imageLayout === 'twoEqualCols',
                        'center-bottom': imageLayout === 'twoStacked' && gridLayout === 'twelveCol',
                        'uneven-col-right': imageLayout === 'twoColsLgLeft' && gridLayout === 'sixCol',
                        'homeImage': true
                    })}
                    ref={addToRefs}
                    // onClick={() => setimageToShow({isOpen: true, img: image2.asset.gatsbyImageData.images.fallback.src})}
                    onClick={() => setOpen({isOpen: true, img: image2.asset.url})}
                    >
                        <GatsbyImage 
                            image={image2.asset.gatsbyImageData} 
                            alt={image2.alt}
                            quality="50"
                            style={{height: '100%'}}
                            className='imgWrapper'
                            imgClassName="imgFile"
                            layout="fixed"
                        />
                </ImageWrapper>
                )}
                {image3 && image3.asset && (
                    <ImageWrapper
                        className= {classNames({
                            'sm-r-b': imageLayout === 'largeLeft' || imageLayout === 'largeTop',
                            'lg-r': imageLayout === 'largeRight',
                            'homeImage': true
                        })}
                        ref={addToRefs}
                        onClick={() => setOpen({isOpen: true, img: image3.asset.url})}
                    >
                            <GatsbyImage 
                                image={image3.asset.gatsbyImageData} 
                                alt={image3.alt}
                                quality="50"
                                style={{height: '100%'}}
                                className='imgWrapper'
                                imgClassName="imgFile"
                                layout="fixed"
                            />
                    </ImageWrapper>
                )}
            </ImageSection>
        </div>
    )
}

export default HomePageSection