import styled from 'styled-components'
import { device } from '../../styles/mediaQueries'

export const ImageSection = styled.section`

@media ${device.mediaMinLarge} {
    width: 100vw;
    height: 100vh;
    gap: 1rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 1rem;

    &.threeColGrid {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }

    &.sixColGrid {
        grid-template-columns: repeat(6, 1fr);
    }

    &.twelveColGrid {
        grid-template-columns: repeat(12, 1fr);
    }

    &.screenHeight {
        height: 100vh;
    }

    &.screenHeightExtended {
        height: 175vh;
    }

    // Image placement classes:

    .lg-l {
        grid-column: 1/2;
        grid-row: 1/3;
    }

    .lg-r {
        grid-column: 2/3;
        grid-row: 1/3;
    }

    .lg-t {
        grid-column: 1/3;
        grid-row: 1/-1;
    }

    .sm-r-t {
        grid-column: 2/3;
        grid-row: 1/2;
    }

    .sm-r-b {
        grid-column: 2/3;
        grid-row: 2/3;
    }

    .sm-l-t {
        grid-column: 1/2;
        grid-row: 1/2;
    }

    .sm-l-b {
        grid-column: 1/2;
        grid-row: 2/3;
    }

    .sm-r-b {
        grid-column: 2/3;
        grid-row: 2/3;
    }

    .center-top {
        grid-column: 3/11;
        grid-row: 1/2;
    }

    .center-bottom {
        grid-column: 3/11;
        grid-row: 2/3;
    }

    .uneven-col-left {
        grid-column: 1/5;
        grid-row: 1/-1;
    }

    .uneven-col-right {
        grid-column: 5/-1;
        grid-row: 1/-1;
    }
}
`

export const ImageWrapper = styled.div` 
position: relative;
overflow: hidden;
padding: 1rem 1rem 0;
cursor: pointer;

.imgWrapper {
    @media ${device.mediaMinLarge} {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
`

export const ImageButton = styled.button` 
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    display:block;
    height: 100%;
    width: 100%;
`